import React from "react";
import Header from "../../Components/Header/header";
import "./style.scss";

const NotFound = () => {
  return (
    <div className="notFound">
      <Header headerv2 />
      <div className="notFound__wrapper">
        <i className="ti  ti-face-sad notFound__wrapper__icon"></i>
        <h3 className="notFound__wrapper__label">Not Found</h3>
      </div>
    </div>
  );
};

export default NotFound;

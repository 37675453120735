import FooterCol from "../FooterCol/footerCol";
import "./style.scss";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__newsletter">
        <h4 className="footerCol__wrapper__title">Newsletter</h4>
        <div className="footer__newsletter__inptWrapper">
          <input
            type="text"
            className="footer__newsletter__inptWrapper__inpt"
            placeholder="Enter Your Email"
          />
          <i className="ti ti-angle-right footer__newsletter__inptWrapper__icon"></i>
        </div>
        <p className="footer__newsletter__paragraph">
          You may unsubscribe at any time. To find out more, please visit our
          Privacy Policy.
        </p>
      </div>

      <div className="footer__wrapper">
        <div className="footer__wrapper__col">
          <FooterCol about />
        </div>
        <div className="footer__wrapper__col">
          <FooterCol mainLisks />
        </div>
        <div className="footer__wrapper__col">
          <FooterCol Collections />
        </div>
        <div className="footer__wrapper__col">
          <FooterCol contact />
        </div>
        {/* <div className="footer__wrapper__col">
          <FooterCol />
        </div> */}
      </div>
      <div className="footer__bottom">
        {/* <div className="footer__bottom__links">
          <p className="footer__bottom__links__link">Careers</p>
          <p className="footer__bottom__links__link">Careers</p>
          <p className="footer__bottom__links__link">Careers</p>
          <p className="footer__bottom__links__link">Careers</p>
          <p className="footer__bottom__links__link">Careers</p>
        </div> */}
        <div className="footer__bottom__icons">
          <i className="footer__bottom__icons__icon ti ti-facebook"></i>
          <i className="footer__bottom__icons__icon ti ti-instagram"></i>
          <i className="footer__bottom__icons__icon ti ti-twitter"></i>
          <i className="footer__bottom__icons__icon ti ti-google"></i>
        </div>
      </div>
    </div>
  );
};

export default Footer;

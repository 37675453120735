import "./App.css";
import Footer from "./Components/Footer/footer";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/home";
import Collection from "./Pages/Collection/collection";
import Product from "./Pages/Product/product";
import NotFound from "./Pages/NotFound/notFound";
import { client } from "../src/config/sanity";
import Loading from "./Components/Loading";
import { createContext, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactUs from "./Pages/Contact/contact";
import About from "./Pages/About";
import Faq from "./Pages/Faq";

export const MyContext = createContext();
function App() {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
    const handleFetch = async () => {
      const query = '*[_type == "product"]';
      const fetchedProducts = await client.fetch(query);
      setProducts(fetchedProducts);
      setLoading(false);
    };
    if (loading) {
      handleFetch();
    }
  }, []);

  return (
    <MyContext.Provider value={products}>
      {loading && <Loading />}
      <Routes>
        <Route path="/" element={<Home allProducts={products} />} />
        <Route
          path="/collection/:category"
          element={<Collection allProducts={products} />}
        />
        <Route
          path="/product/:slug"
          element={<Product allProducts={products} />}
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </MyContext.Provider>
  );
}

export default App;

import React, { useContext } from "react";
import Hero from "../../Components/Hero/hero";
import ProductsCollection from "../../Components/productsCollection";
import ImageWithContent from "../../Components/ImageWithContent/imageWithContent";
import BenefitsIcons from "../../Components/BenefitsIcons/benefitsIcons";
import Header from "../../Components/Header/header";
import GridProducts from "../../Components/GridProducts";
import ProductsSlider from "../../Components/ProductsSlider";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "./style.scss";
import { EffectCards } from "swiper";
import TinderCardWithTitle from "../../Components/TinderCardWithtitle";

const Home = ({ allProducts }) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <Hero isVideo={!false} />
      <ProductsCollection />
      <GridProducts allProducts={allProducts.slice(0, 4)} />
      <ProductsSlider allProducts={allProducts} />
      <h3 className="hero__content__title text-black home__featured--title hideOnComputers">
        Featured Dresses
      </h3>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper--home"
      >
        <SwiperSlide>
          <img
            data-aos="zoom-in"
            alt="cards"
            src="/assets/images/iloveimg-resized/1.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="cards"
            data-aos="zoom-in"
            data-aos-duration="20000"
            src="/assets/images/iloveimg-resized/2.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="cards"
            data-aos="zoom-in"
            data-aos-duration="40000"
            src="/assets/images/iloveimg-resized/3.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="cards"
            data-aos="zoom-in"
            data-aos-duration="60000"
            src="/assets/images/iloveimg-resized/4.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="cards"
            data-aos="zoom-in"
            data-aos-duration="90000"
            src="/assets/images/iloveimg-resized/5.jpg"
          />
        </SwiperSlide>
      </Swiper>
      <TinderCardWithTitle />
      {/* <CollectionSlider /> */}
      <ProductsSlider
        title="Tulle"
        allProducts={allProducts?.filter((i) => i?.category == "tulle-dresses")}
      />
      <ImageWithContent />
      <BenefitsIcons />
    </div>
  );
};

export default Home;

import React from "react";
import { categories } from "../../other/data";
import "./style.scss";
import { Link } from "react-router-dom";
const FooterCol = ({
  about = false,
  mainLisks = false,
  Collections = false,
  contact = false,
}) => {
  if (about) {
    return (
      <div className="footerCol">
        <div className="footerCol__wrapper">
          <h4 className="footerCol__wrapper__title">About us</h4>
          <div className="footerCol_wrapper__links">
            <p className="footerCol__wrapper__links__link">
              Elie Bejjani, the visionary fashion designer from Beirut,
              captivated the world with sleek and modern designs. His eponymous
              label became an industry icon, dressing influential women and
              gracing the pages of Vogue Arabia and more. A true innovator with
              impeccable attention to detail, Elie shines on runways worldwide.
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (mainLisks) {
    return (
      <div className="footerCol">
        <div className="footerCol__wrapper">
          <h4 className="footerCol__wrapper__title">Main Links</h4>
          <div className="footerCol_wrapper__links">
            <Link to="/" className="aLink">
              <p className="footerCol__wrapper__links__link">Home</p>
            </Link>
            <Link to="/contact" className="aLink">
              <p className="footerCol__wrapper__links__link">Contact us</p>
            </Link>
            {/* <p className="footerCol__wrapper__links__link">Terms & Condition</p> */}
            <Link to="/about" className="aLink">
              <p className="footerCol__wrapper__links__link">About us </p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  if (Collections) {
    return (
      <div className="footerCol">
        <div className="footerCol__wrapper">
          <h4 className="footerCol__wrapper__title">Explore </h4>
          <div className="footerCol_wrapper__links">
            {categories.map((cat) => (
              <Link className="aLink" key={cat.id} to={`${cat.href}`}>
                <p className="footerCol__wrapper__links__link">{cat.label}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
  if (contact) {
    return (
      <div className="footerCol">
        <div className="footerCol__wrapper">
          <h4 className="footerCol__wrapper__title">Reach us</h4>
          <div className="footerCol_wrapper__links">
            <Link to="/contact" className="aLink">
              <p className="footerCol__wrapper__links__link">Contact us</p>
            </Link>
            <Link
              to="https://maps.app.goo.gl/rxCMFBConFs2PKkv8"
              className="aLink"
            >
              <p className="footerCol__wrapper__links__link">
                Visit our showroom
              </p>
            </Link>
            <Link className="aLink" to="https://calendly.com/">
              <p className="footerCol__wrapper__links__link">
                Book an Appointment
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="footerCol">
        <div className="footerCol__wrapper">
          <h4 className="footerCol__wrapper__title">Customer Care</h4>
          <div className="footerCol_wrapper__links">
            <p className="footerCol__wrapper__links__link">FAQ</p>
            <p className="footerCol__wrapper__links__link">
              Exchanges and Returns
            </p>
            <p className="footerCol__wrapper__links__link">Shipping Policy</p>
          </div>
        </div>
      </div>
    );
  }
};

export default FooterCol;

import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header/header";
import Loading from "../../Components/Loading";
const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 1500);
    return () => clearTimeout(timer);
  });

  return (
    <div className="contact">
      <Header headerv2 />
      {loading && <Loading />}

      <div className="contact__wrapper">
        <h3 className="hero__content__title contact__wrapper__title text-black">
          Contact Us
        </h3>

        <div className="contact__wrapper__form">
          <input
            type="text"
            placeholder="Type your name"
            className="contact__wrapper__form__input"
          />
          <input
            type="email"
            placeholder="Type your email"
            className="contact__wrapper__form__input"
          />
          <textarea
            name=""
            placeholder="Type your message"
            className="contact__wrapper__form__input"
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <div
            className={`hero__content__btn itemContent__wrapper__btn contact__wrapper__form__btn`}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { Navigation, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";

const Hero = ({ isVideo }) => {
  const navigate = useNavigate();
  // const videoUrl1 = "/assets/videos/1.mp4";
  // const videoUrl2 = "/assets/videos/Banner1.mp4";
  const videos = [
    {
      url: "/assets/videos/vid.mov",
      subTitle: "",
      title: "",
      href: "",
      btnLabel: "Shop Now",
    },
    // {
    //   url: "/assets/videos/1.mp4",
    //   subTitle: "",
    //   title: "",
    //   href: "",
    //   btnLabel: "Shop Now",
    // },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const handleVideoLoad = () => {};
  return (
    <>
      {isVideo ? (
        <Swiper
          pagination={true}
          modules={[Pagination, Navigation]}
          navigation={true}
          className="mySwiper"
          loop
        >
          {/* {isLoading && ( */}

          {/* )} */}
          {videos.map((i) => (
            <SwiperSlide key={i.url}>
              {isLoading && (
                <div className="hero__loading">
                  <div className="hero__loading__animation"></div>
                </div>
              )}
              <video
                autoPlay
                preload="auto"
                loop
                muted
                onLoadedData={() => {
                  setIsLoading(false);
                }}
              >
                <source src={i.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="hero__content">
                <h3 className="hero__content__subtitle">SS2024</h3>
                <h3 className="hero__content__title">
                  May my design bring out the "Queen" in every woman out there.
                </h3>
                <div
                  onClick={() => {
                    navigate("/collection/tulle-dresses");
                  }}
                  className="hero__content__btn"
                >
                  Shop Now
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="heroImage">
          {/* <img src={imgUrl} alt="" className="heroImage__img" /> */}
          <div className="hero__content">
            {/* <h4 className="hero__content__subtitle">Bridal Spring 2023</h4> */}
            {/* <h3 className="hero__content__title"> Evening Dresses </h3> */}
            {/* <div className="hero__content__btn">Shop Now</div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default Hero;

import React from "react";
import "./style.scss";
import Card from "../Card/card";
import { Link, useNavigate } from "react-router-dom";
import { urlFor } from "../../config/sanity";

const GridProducts = ({ allProducts }) => {
  const navigate = useNavigate();
  const products = [
    {
      id: 1,
      name: "First Dress",
      image: "/assets/images/iloveimg-resized/1.jpg",
      link: "",
      price: "999",
      collection: "",
      stock: true,
      gallery: [],
      size: [],
      colors: [],
    },
    {
      id: 2,
      name: "Second Dress",
      image: "/assets/images/iloveimg-resized/2.jpg",
      link: "",
      price: "999",
      collection: "",
      stock: true,
    },
    {
      id: 3,
      name: "Third Dress",
      image: "/assets/images/iloveimg-resized/3.jpg",
      link: "",
      price: "999",
      collection: "",
      stock: true,
    },
    {
      id: 4,
      name: "Fourth Dress",
      image: "/assets/images/iloveimg-resized/4.jpg",
      link: "",
      price: "999",
      collection: "",
      stock: true,
    },
  ];
  return (
    <div className="gridProducts">
      <h3 className="hero__content__subtitle text-black">Bridal Spring 2023</h3>
      <h3 className="hero__content__title text-black text-smaller">
        Spring Summer 2023
      </h3>
      <div className="gridProducts__wrapper">
        {allProducts?.map((item) => (
          <div key={item.name} className="gridProducts__wrapper__item">
            <Link
              className="no-td"
              id={item.name}
              to={`/product/${item.slug.current}`}
            >
              <Card
                image={urlFor(item.mainImage)}
                title={item.name}
                price={item.price}
              />
            </Link>
          </div>
        ))}
      </div>
      <div
        onClick={() => {
          navigate("/collection/tulle-dresses");
        }}
        className="hero__content__btn gridProducts__wrapper__btn bg-black text-white"
      >
        Show More
      </div>
    </div>
  );
};

export default GridProducts;

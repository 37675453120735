import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header/header";
import Loading from "../../Components/Loading";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 1500);
    return () => clearTimeout(timer);
  });
  return (
    <div className="about">
      <Header headerv2 />
      {loading && <Loading />}

      <div className="about__wrapper">
        <h3 className="hero__content__title  about__wrapper__title text-black">
          About Us
        </h3>
        <p className="about__wrapper__paragraph">
          Growing up in the bustling city of Beirut, Elie Bejjani was always
          drawn to the world of fashion. From a young age, sketching designs and
          experimenting with different fabrics and textures was all he did on
          his free time. In 2008, he launched his eponymous fashion label |Elie
          Bejjani| in Paris Fashion Week, at Porte de Versaille, which quickly
          became a household name in the industry. His designs were mostly known
          for their sleek, modern, aesthetic and impeccable attention to detail.
          He quickly gained a reputation for being one of the most innovative
          and exciting designers of his generation. Over the years, Elie has
          dressed some of the most influential and stylish women in the Middle
          East such as Rahma Riad, Sarah Abi Kanaan, Jessy Abdo, Romina Matar
          and many others. His designs have been featured in countless fashion
          magazines such as Vogue Arabia, Prestige and many other online
          articles were written about his slick and fancy designs, such as
          Lamasat Online, and he exhibited his magic on the runway at many major
          fashion weeks in Paris that took place in : 2008 La Porte De Versaille
          (Paris Fashion Week) 2009 Jardin De Tuileries (Paris Fashion Week)
          2010 Salon Des Createurs St. James Hotel (Paris Fashion Week) 2011
          Salon Des Createurs Westin Hotel (Paris Fashion Week) 2012 Salon Des
          Createurs Hotel Californie Champs Elysee (Paris Fashion Week)
          2012/2013/2014/2015/2016 Private showroom, selling office OD
          Excellence France 2016/2017/2018 IF Wedding Fashion IZMIR (Istanbul
          Fashion Week) ifwedding.izfas.com.tr 2018 Full Private Fashion Show
          for his Spring/Summer collection at Four Seasons hotel Beirut.
        </p>
      </div>
    </div>
  );
};

export default About;

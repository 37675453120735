import React from "react";

const Loading = () => {
  return (
    <div className="banter-loader-parent">
      <div class="banter-loader">
        {Array(9)
          .fill(0)
          .map((i) => (
            <div class="banter-loader__box"></div>
          ))}
      </div>
    </div>
  );
};

export default Loading;

import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { menuItems } from "../../other/data";

const MobileHeader = ({ isHidden, setIsHidden }) => {
  const handleClose = () => {
    setIsHidden(!isHidden);
  };

  return (
    // !isHidden && (
    <div className={`mobileHeader ${!isHidden && "mobileHeader--show"}`}>
      <div className="mobileHeader__wrapper">
        <i
          className="mobileHeader__wrapper__close ti ti-close"
          onClick={handleClose}
        ></i>
        <div className="mobileHeader__wrapper__list">
          {menuItems.map((i) => (
            <Link key={i.id} className="aLink" to={i.href}>
              <div className="mobileHeader__wrapper__list__item">
                <div className="mobileHeader__wrapper__list__item__label">
                  {i.label}
                </div>
                <i className="mobileHeader__wrapper__list__item__arrow ti ti-angle-right"></i>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
    // )
  );
};

export default MobileHeader;

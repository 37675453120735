import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "jzh2d17g",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: true,
  token:
    "skpwNWI9z4UiT78C4hP75xpnCCNBmeE9Gxx5HItSXlPM5hyLvBP5EEwsMIq3rV2HsHYi53I2RjtrLO9z9Mi0mcZyLwKVBMWSuD38Fh6bh6Paa5cTX3KI9m1Uxw3zKYbWabf4tCKqvYywYuyDmey7CzN83wYluDNyvhkxEhr93SMGHlRuJ31w",
});
const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

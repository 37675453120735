// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDL8mfdRIkyWDS6fjku9EFCaUJXY_vq6KM",
  authDomain: "eliebejjani-ae524.firebaseapp.com",
  projectId: "eliebejjani-ae524",
  storageBucket: "eliebejjani-ae524.appspot.com",
  messagingSenderId: "683324390876",
  appId: "1:683324390876:web:af64cab3844b28f2dbe319",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;

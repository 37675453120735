import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, FreeMode, Navigation } from "swiper";
import "./style.scss";
import Card from "../Card/card";
import { urlFor } from "../../config/sanity";
import { Link } from "react-router-dom";

const ProductsSlider = ({ allProducts, title = "Evening Dresses" }) => {
  return (
    <div className="productsSlider">
      <div className="productsSlider__wrapper">
        <h3 className="hero__content__title text-black"> {title} </h3>
        <Swiper
          navigation={true}
          slidesPerView={1}
          modules={[Navigation, FreeMode]}
          speed={2000} // Adjust the speed to control the duration of each slide transition
          freeMode={true}
          breakpoints={{
            1000: {
              slidesPerView: 2,
            },
            330: {
              slidesPerView: 1.3,
            },
            380: {
              slidesPerView: 1.7,
            },
            400: {
              slidesPerView: 1.4,
            },
            1200: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 3.4,
            },
          }}
          pagination={{
            clickable: true,
          }}
          className="productsCollection__wrapper__right__swiper"
        >
          {allProducts?.length &&
            allProducts?.map((i) => (
              <SwiperSlide key={i.id} className="">
                <div className="productsSlider__wrapper__item">
                  <Link className="aLink" to={`/product/${i.slug.current}`}>
                    <Card
                      image={urlFor(i.mainImage)}
                      price={i.price}
                      title={i.name}
                      key={i.id}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          <SwiperSlide
            key={999}
            className="productsSlider__wrapper__item--last"
          >
            <div className="productsSlider__wrapper__item--last__link">
              <Link
                className="aLink"
                to={`/collection/${allProducts[0]?.category || ""}`}
              >
                <p className="productsSlider__wrapper__item--last__link__label">
                  Show More
                </p>
              </Link>
              <i className="productsSlider__wrapper__item--last__link__icon ti ti-angle-right"></i>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ProductsSlider;

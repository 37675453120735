import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import "./style.css";
import { faqData } from "../../other/data";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        className="faq--btn"
      >
        {header}
        <i className="tr ti-angle-down chevron"></i>
      </div>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) =>
        `${`itemBtn`} ${isEnter && `itemBtnExpanded`}`,
    }}
    contentProps={{ className: `itemContent` }}
    panelProps={{ className: `itemPanel` }}
  />
);

export default function FaqItem() {
  return (
    <div className={``} style={{ paddingTop: "20px" }}>
      <Accordion transition transitionTimeout={250}>
        {faqData.map((i) => (
          <AccordionItem key={i.q} className={"faq"} header={i.q}>
            {i.a}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

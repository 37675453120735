import React, { useEffect, useState } from "react";
import "./style.scss";
import MobileHeader from "../MobileHeader/mobileheader";
import Search from "../Search/search";
import { Link } from "react-router-dom";
import { menuItems } from "../../other/data";

const Header = ({ headerv2 }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isHidden, setIsHidden] = useState(true);
  const [isSearchHidden, setIsSearchHidden] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="" style={{ position: "relative" }}>
      <MobileHeader isHidden={isHidden} setIsHidden={setIsHidden} />
      <Search
        isSearchHidden={isSearchHidden}
        setIsSearchHidden={setIsSearchHidden}
      />
      <div
        className={`header ${headerv2 ? "header2" : ""} ${
          scrollPosition > 0 ? "header--scroll" : ""
        }`}
      >
        <div className="header__wrapper">
          <div className="header__wrapper__left">
            <a href="#a" className="hideOnMobile">
              {/* Lebanon */}
            </a>
            <i
              onClick={() => {
                setIsHidden(!isHidden);
              }}
              className="ti ti-align-right hideOnComputers no_highlights hamburger header__wrapper__right__icon"
            ></i>
          </div>
          <Link to="/" className="aLink">
            <div className="header__wrapper__mid">
              {/* <h1 className="header__wrapper__mid__title">Elie Bejjani</h1> */}
              <img
                src="/assets/images/logo2.png"
                alt=""
                style={
                  !scrollPosition > 0
                    ? {
                        filter: "invert(121) hue-rotate()",
                      }
                    : {}
                }
                srcset=""
              />
            </div>
          </Link>
          <div className="header__wrapper__right">
            <i
              onClick={() => {
                setIsSearchHidden(!isSearchHidden);
              }}
              className="ti ti-search header__wrapper__right__icon no_highlights"
            ></i>
            <a
              className="aLink"
              href="https://maps.app.goo.gl/rxCMFBConFs2PKkv8"
            >
              <i className="ti ti-location-pin header__wrapper__right__icon no_highlights"></i>
            </a>
          </div>
        </div>
        <div className="header__lowerHeader">
          <div className="header__lowerHeader__wrapper">
            <ul className="header__lowerHeader__wrapper__list">
              {menuItems.map((i) => (
                <Link key={i.id} className="aLink" to={i.href}>
                  <li className="header__lowerHeader__wrapper__list__item">
                    {i.label}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import "./style.scss";

const Card = ({ image, title, price }) => {
  const imgUrl =
    "https://cdn.shopify.com/s/files/1/0572/0345/8247/products/15009_Moonflower_1.jpg?v=1677848593";
  return (
    <div data-aos="zoom-in" className="card">
      <div className="card__wrapper">
        <img
          src={image ? image : imgUrl}
          alt=""
          loading="lazy"
          className="card__wrapper__img"
        />
        <h4 className="card__wrapper__title">
          {title ? title : "V-Cut Dress"}
        </h4>
        {/* <p className="card__wrapper__price">{price ? price : 900}$</p> */}
      </div>
    </div>
  );
};

export default Card;

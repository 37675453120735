import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const ProductsCollection = () => {
  const navigate = useNavigate();
  const slides = [
    {
      id: 1,
      link: "",
      img: "/assets/images/iloveimg-resized/1.jpg",
    },
    {
      id: 2,
      link: "",
      img: "/assets/images/iloveimg-resized/2.jpg",
    },
    {
      id: 3,
      link: "",
      img: "/assets/images/iloveimg-resized/3.jpg",
    },
    {
      id: 4,
      link: "",
      img: "/assets/images/iloveimg-resized/4.jpg",
    },
    {
      id: 5,
      link: "",
      img: "/assets/images/iloveimg-resized/5.jpg",
    },
  ];

  return (
    <div className="productsCollection">
      <div className="productsCollection__wrapper">
        <div className="hero__contentx productsCollection__wrapper__left">
          <h3 className="hero__content__subtitle text-black productsCollection__wrapper__left__subtitle">
            Bridal Spring 2023
          </h3>
          <h3 className="hero__content__title text-black productsCollection__wrapper__left__title text-smaller">
            Spring Summer 2023
          </h3>
          <div className="hero__content__btn productsCollection__wrapper__left__btn">
            Shop Now
          </div>
        </div>
        <div className="productsCollection__wrapper__right">
          <Swiper
            navigation={true}
            slidesPerView={1}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 2000, // Adjust the delay to control the time between each slide transition
              disableOnInteraction: false, // Keep autoplay even when user interacts with the slider
            }}
            speed={2000} // Adjust the speed to control the duration of each slide transition
            freeMode={true}
            breakpoints={{
              1000: {
                slidesPerView: 1.7,
              },
              380: {
                slidesPerView: 1.2,
              },
              400: {
                slidesPerView: 1.4,
              },
              //   567: {
              //     slidesPerView: 1.25,
              //   },
              //   768: {
              //     slidesPerView: 2,
              //   },
              //   992: {
              //     slidesPerView: 2.5,
              //   },
              1200: {
                slidesPerView: 1.5,
              },
              1400: {
                slidesPerView: 1.5,
              },
            }}
            pagination={{
              clickable: true,
            }}
            // modules={[FreeMode]}
            className="productsCollection__wrapper__right__swiper"
          >
            {slides.map((i) => (
              <SwiperSlide key={i.id} className="">
                <div
                  style={{ backgroundImage: `url(${i.img})` }}
                  className="productsCollection__wrapper__right__swiper__item"
                />
                {/* <ResizedImage src={i.img} width={300} height={400} /> */}
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            onClick={() => {
              navigate("/collection/bridal-dresses");
            }}
            className="hero__content__btn productsCollection__wrapper__right__btn bg-black text-white"
          >
            Shop Now
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCollection;

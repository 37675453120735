import React, { useEffect, useState } from "react";
import Hero from "../../Components/Hero/hero";
import Card from "../../Components/Card/card";
import "./style.scss";
import Header from "../../Components/Header/header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { urlFor } from "../../config/sanity";
import Loading from "../../Components/Loading";

const Collection = ({ allProducts }) => {
  const { category } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const handleSortBy2 = async (e) => {
    const { innerHTML: value } = e.target;
    setSortByOpen((prev) => !prev);
    switch (value) {
      case "Alphabetically, A-Z":
        //logic
        const myProduct1 = products.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setProducts(myProduct1);
        console.log(products);
        break;
      case "Alphabetically, Z-A":
        const myProducts2 = products.sort((a, b) =>
          b.name.localeCompare(a.name)
        );

        setProducts(myProducts2);

        break;
      case "Price, low to high":
        const myProducts3 = products.sort((a, b) => a.price - b.price);
        setProducts(myProducts3);

        //logic
        break;

      case "Price, high to low":
        //logic
        const myProducts4 = products.sort((a, b) => b.price - a.price);
        setProducts(myProducts4);

        break;

      default:
        break;
    }
  };
  function fetchProducts() {
    if (category && allProducts.length) {
      const out = allProducts.filter((p) => p.category === category);
      setProducts(out);
      if (out.length === 0) {
        navigate("/");
      }
    }
  }
  const handleSortBy = (e) => {
    setSortByOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts();
  }, [category, allProducts]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  });
  const [isSortByOpen, setSortByOpen] = useState(false);
  return (
    <div className="collection">
      {loading && <Loading />}
      <Header />
      <Hero isVideo={false} />
      {isSortByOpen && (
        <div
          onClick={() => {
            setSortByOpen(!isSortByOpen);
          }}
          className="overlay"
        ></div>
      )}
      <div className="collection__paragraph">
        <p className="collection__paragraph__label">
          Shop blouses and tops with feather trims designed to complement your
          signature look of the day.
        </p>
      </div>
      <div className="collection__filterWrapper">
        {isSortByOpen && (
          <div className="collection__filterWrapper__sortParent">
            {[
              "Alphabetically, A-Z",
              "Alphabetically, Z-A",
              "Price, low to high",
              "Price, high to low",
            ].map((i) => (
              <div
                key={i}
                onClick={handleSortBy2}
                className="collection__filterWrapper__sortParent__item"
              >
                {i}
              </div>
            ))}
          </div>
        )}
        <div className="collection__filterWrapper__parent">
          <div
            className="collection__filterWrapper__parent__left"
            onClick={() => {
              setSortByOpen(!isSortByOpen);
            }}
          >
            <i className="collection__filterWrapper__parent__left__icon ti ti-filter"></i>
            Filter and Sort
          </div>
          <div className="collection__filterWrapper__parent__right">
            {products.length ? (
              `${products.length} products`
            ) : (
              <div class="loader-count"></div>
            )}
          </div>
        </div>
      </div>
      <div className="collection__wrapper">
        {products.length ? (
          products.map((product) => (
            <div className="collection__wrapper__item">
              <Link
                className="no-td"
                key={product.name}
                to={`/product/${product.slug.current}`}
              >
                <Card
                  image={urlFor(product.mainImage)}
                  title={product.name}
                  price={product.price}
                />
              </Link>
            </div>
          ))
        ) : (
          <div className="banter-loader-parent">
            <div class="banter-loader">
              {Array(9)
                .fill(0)
                .map((i) => (
                  <div class="banter-loader__box"></div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Collection;

import React from "react";
import { Parallax } from "react-parallax";
import ItemContent from "../ItemContent/itemContent";
import "./style.scss";
const ImageWithContent = ({
  image = "/assets/images/iloveimg-resized/1.jpg",
}) => {
  return (
    <div className="imageWithContent">
      <div className="imageWithContent__wrapper">
        <Parallax className="xxxx" bgImage={image} strength={150}>
          <div className="imageWithContent__parallax">
            <ItemContent
              titleClass="text-white-2"
              subTitleClass="text-white-2"
              paragraph='May my design bring out the "Queen" in every woman out there'
              btnClass="bg-bg text-white imageWithContent__btn"
            />
          </div>
        </Parallax>
      </div>
    </div>
  );
};

export default ImageWithContent;

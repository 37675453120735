import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header/header";
import Loading from "../../Components/Loading";
import Example from "../../Components/FaqItem";

const Faq = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  });
  return (
    <div className="faq">
      <Header headerv2 />
      {loading && <Loading />}

      <div className="faq__wrapper">
        <h3 className="hero__content__title  about__wrapper__title text-black">
          FAQ
        </h3>
        <Example />
      </div>
    </div>
  );
};

export default Faq;

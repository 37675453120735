import React from "react";
import "./style.scss";
const BenefitsIcons = () => {
  return (
    <div className="benefitsIcons">
      <div className="benefitsIcons__wrapper">
        <div className="benefitsIcons__wrapper__item">
          <i className="ti  ti-truck  benefitsIcons__wrapper__item__icon"></i>
          <h3 className="benefitsIcons__wrapper__item__subtitle">
            COMPLIMENTARY SHIPPING
          </h3>
          <p className="benefitsIcons__wrapper__item__paragraph">
            Benefit from complimentary shipping worldwide wherever you are
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=96171806553&text=hello"
            className="aLink"
          >
            <div className="hero__content__btn benefitsIcons__btn">
              Whatsapp
            </div>
          </a>
        </div>
        {/* <div className="benefitsIcons__wrapper__item">
          <i className="ti ti-user benefitsIcons__wrapper__item__icon"></i>
          <h3 className="benefitsIcons__wrapper__item__subtitle">
            PRIVATE SESSION
          </h3>
          <p className="benefitsIcons__wrapper__item__paragraph">
            Book an online or in-person appointment with your trusted Fashion
            Advisor
          </p>
        </div> */}
        {/* <div className="benefitsIcons__wrapper__item">
          <i className="ti ti-package benefitsIcons__wrapper__item__icon"></i>
          <h3 className="benefitsIcons__wrapper__item__subtitle">
            Click & Collect
          </h3>
          <p className="benefitsIcons__wrapper__item__paragraph">
            You can now order your item online and pick it up from your nearest
            Boutique
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default BenefitsIcons;

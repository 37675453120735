export const categories = [
  {
    id: 1,
    label: "Tulle",
    href: "/collection/tulle-dresses",
  },
  {
    id: 2,
    label: "Satin",
    href: "/collection/satin-dresses",
  },
  {
    id: 3,
    label: "Embroidered",
    href: "/collection/embroidered-dresses",
  },
  {
    id: 4,
    label: "Bridal",
    href: "/collection/bridal-dresses",
  },
];

export const menuItems = [
  {
    id: 1,
    label: "Home",
    href: "/",
  },
  ...categories,
  // {
  //   id: 6,
  //   label: "Book an Appointment",
  //   href: "https://calendly.com/",
  // },
  {
    id: 5,
    label: "About us",
    href: "/about",
  },
  // {
  //   id: 7,
  //   label: "FAQ",
  //   href: "/faq",
  // },
  {
    id: 4,
    label: "Contact us",
    href: "/contact",
  },
];

export const faqData = [
  {
    q: "What is your return policy for bridal and evening dresses?",
    a: "We have a flexible return policy for bridal and evening dresses. If the dress doesn't meet your expectations, you can return it within [X] days of receiving it. Please ensure that the dress is unworn, in its original condition, and with all tags attached. For more details, please refer to our Returns & Exchanges page",
  },
  {
    q: "How can I determine the right size for my bridal or evening dress?",
    a: "To find the perfect size for your bridal or evening dress, we recommend referring to our detailed size chart available on the product page. Additionally, you can contact our customer support team for personalized assistance in selecting the right size based on your measurements.",
  },
  {
    q: "Can I request customizations or alterations for a bridal or evening dress?",
    a: "Yes, we offer customization and alteration services for our bridal and evening dresses. Please reach out to our customer support team with your specific requirements, and we will be happy to assist you with the available options and any additional costs involved",
  },
  {
    q: "What is the estimated delivery time for bridal and evening dresses?",
    a: "WeOur estimated delivery time for bridal and evening dresses is typically [X] days. However, please note that certain factors, such as customization requests or unforeseen circumstances, may slightly impact the delivery timeline. Rest assured, we strive to deliver your dress as soon as possible while ensuring the highest quality",
  },
  {
    q: "Are the colors of the dresses on the website accurate?",
    a: "We make every effort to display the colors of our dresses as accurately as possible on our website. However, please note that colors may vary slightly due to different monitor settings and lighting conditions. If you have any concerns about a specific color, please contact our customer support team, and we can provide further assistance or additional images if available",
  },
];

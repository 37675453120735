import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const ItemContent = ({ subTitleClass, titleClass, btnClass, paragraph }) => {
  const navigate = useNavigate();
  return (
    <div className="itemContent">
      <div className="hero__contentx itemContent__wrapper">
        <h3
          className={`hero__content__subtitle itemContent__wrapper__subtitle text-black ${subTitleClass}`}
        >
          SS2024
        </h3>
        <h3
          className={`hero__content__title itemContent__wrapper__title m-0 text-black ${titleClass}`}
        >
          Elie Bejjani
        </h3>
        {paragraph && (
          <p className="itemContent__wrapper__paragraph withImg">{paragraph}</p>
        )}
        <div
          onClick={() => {
            navigate("/collection/bridal-dresses");
          }}
          className={`hero__content__btn itemContent__wrapper__btn ${btnClass}`}
        >
          Shop Now
        </div>
      </div>
    </div>
  );
};

export default ItemContent;

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/header";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductsSlider from "../../Components/ProductsSlider";
import { urlFor } from "../../config/sanity";
import Loading from "../../Components/Loading";

const Product = ({ allProducts }) => {
  const { slug } = useParams();
  const [product, setProduct] = useState([]);
  const [colorSelected, setColorSelected] = useState("");
  const [sizeSelected, setsizeSelected] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usedImage, setUsedImage] = useState("");

  function fetchProducts() {
    if (slug && allProducts.length) {
      const [out] = allProducts.filter((p) => p.slug.current === slug);
      setProduct(out);
      if (!out?.name?.length) {
        navigate("/");
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts();
    // Add allProducts as a dependency for the useEffect hook
  }, [slug, allProducts]);

  useEffect(() => {
    if (product.name) {
      setUsedImage(urlFor(product.mainImage));
    }
  }, [product]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  // if (!product?.name) {
  //   return (
  //     <div className="banter-loader-parent">
  //       <div class="banter-loader">
  //         {Array(9)
  //           .fill(0)
  //           .map((i) => (
  //             <div class="banter-loader__box"></div>
  //           ))}
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="product">
      {loading && <Loading />}

      <Header headerv2 />
      <div className="product__back">
        <Link
          className="aLink m-0 p-0 cp"
          to={`/collection/${product.category}`}
        >
          <i className="ti ti-angle-left product__back__icon"></i>
          <span className="product__back__label">{product.category}</span>
        </Link>
      </div>
      {product.name ? (
        <div className="product__wrapper">
          <div className="product__wrapper__left">
            <div className="product__wrapper__left__grid">
              {console.log(urlFor(product.mainImage))}
              {product?.gallery?.length &&
                [product.mainImage, ...product?.gallery]?.map((i) => (
                  <img
                    src={urlFor(i)}
                    alt=""
                    onClick={() => setUsedImage(urlFor(i))}
                    className="product__wrapper__left__grid__img"
                  />
                ))}
              {/* : (
                <img
                  src={urlFor(product.mainImage)}
                  alt=""
                  onClick={() => setUsedImage(urlFor(product.mainImage))}
                  className="product__wrapper__left__grid__img"
                />
              )} */}
            </div>
            <img
              src={usedImage}
              alt=""
              className="product__wrapper__left__img"
            />
          </div>
          <div className="product__wrapper__right">
            <h3 className="product__wrapper__right__title">{product.name}</h3>
            {/* <p className="product__wrapper__right__price">
              ${product.price} <span>/ Approx</span>
            </p> */}
            {product?.colors && product?.colors.length && (
              <div className="product__wrapper__right__attr">
                <h4 className="product__wrapper__right__attr__title">Color</h4>
                <div className="product__wrapper__right__attr__wrapper">
                  {product?.colors &&
                    product?.colors.length > 0 &&
                    product?.colors?.map((color) => (
                      <div
                        onClick={(e) => {
                          setColorSelected(color);
                        }}
                        className={`product__wrapper__right__attr__wrapper__item color ${
                          colorSelected === color ? "active" : ""
                        }`}
                        style={{ backgroundColor: color }}
                      ></div>
                    ))}
                </div>
              </div>
            )}
            {product?.sizes > 0 && product?.sizes.length && (
              <div className="product__wrapper__right__attr">
                <h4 className="product__wrapper__right__attr__title">Size</h4>
                <div className="product__wrapper__right__attr__wrapper">
                  {product?.sizes > 0 &&
                    product?.sizes.length &&
                    product?.sizes?.map((size) => (
                      <div
                        onClick={(e) => {
                          setsizeSelected(size);
                        }}
                        className={`product__wrapper__right__attr__wrapper__item size ${
                          sizeSelected === size ? "active" : ""
                        }`}
                      >
                        {size}
                      </div>
                    ))}
                </div>
              </div>
            )}

            <div className="product__wrapper__right__btnParent">
              <a
                href={`https://api.whatsapp.com/send?phone=96171806553&text=Hello+I+would+like+to+know+the+rental+price+of+this+dress+in:%0aProduct Name: ${
                  product?.name
                }%0a${sizeSelected ? `Size: ${sizeSelected}%0a` : ""}${
                  colorSelected ? `Color: ${colorSelected}` : ""
                }`}
                target="_blank"
                rel="noreferrer"
                className="aLink"
              >
                <div className="product__wrapper__right__btnParent__btn ">
                  Rent via WhatsApp
                </div>
              </a>
            </div>
            <div className="product__wrapper__right__btnParent">
              <a
                href={`https://api.whatsapp.com/send?phone=96171806553&text=Hello+i+iw+would+like+to+know+the+price+for+this+dress+in:%0aProduct Name: ${
                  product?.name
                }%0a${sizeSelected ? `Size: ${sizeSelected}%0a` : ""}${
                  colorSelected ? `Color: ${colorSelected}` : ""
                }`}
                target="_blank"
                rel="noreferrer"
                className="aLink"
              >
                <div className="product__wrapper__right__btnParent__btn ">
                  Purchase via WhatsApp
                </div>
              </a>
            </div>
            <div className="product__wrapper__right__description">
              <h4 className="product__wrapper__right__description__title">
                Description
              </h4>
              <p className="product__wrapper__right__description__paragraph">
                {product.description}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>LOADING</>
      )}
      <div className="product__2nd">
        <ProductsSlider
          allProducts={allProducts?.filter(
            (p) => p?.category === product?.category
          )}
        />
      </div>
    </div>
  );
};

export default Product;

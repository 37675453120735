import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { MyContext } from "../../App";
import { urlFor } from "../../config/sanity";
const Search = ({ isSearchHidden, setIsSearchHidden }) => {
  const products = useContext(MyContext);

  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsSearchHidden(!isSearchHidden);
  };
  useEffect(() => {
    const handleFetch = async () => {
      if (search.length) {
        setIsLoading(true);
        const productsData = products.filter(
          (i) => i.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );

        if (productsData.length) {
          setIsLoading(false);
          setResult(productsData);
        }
        // else {
        //   setIsLoading(false);
        //   setResult(productsData);
        // }
      }
    };
    handleFetch();
  }, [search]);
  return (
    <div className={`search ${!isSearchHidden && "search--show"}`}>
      <div className="search__wrapper">
        <div className="search__wrapper__close"></div>
        <i
          className="search__wrapper__close ti ti-close"
          onClick={handleClose}
        ></i>
        <div className="search__wrapper__inputWrapper">
          <input
            placeholder="Search"
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="search__wrapper__inputWrapper__input"
          />
          {isLoading && (
            <div className=" search__wrapper__inputWrapper__loader loader"></div>
          )}
          <i className="search__wrapper__inputWrapper__icon ti ti-search"></i>
        </div>
        <div className="search__wrapper__list">
          {result?.length > 0 && search.length !== 0 ? (
            result?.map((i) => (
              <Link
                onClick={handleClose}
                className="aLink"
                key={i.id}
                to={`/product/${i.slug.current}`}
              >
                <div key={i.id} className="search__wrapper__list__item df ">
                  <img
                    src={urlFor(i.mainImage)}
                    alt=""
                    className="search__wrapper__list__item__img"
                  />
                  <div className="">
                    <p className="search__wrapper__list__item__name">
                      {i.name}
                    </p>
                    <p className="search__wrapper__list__item__price">
                      {i.price} $
                    </p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;

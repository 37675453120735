import React from "react";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";
import "./style.scss";

const TinderCardWithTitle = () => {
  return (
    <div className="tinderCardWithTitle">
      <div className="tinderCardWithTitle__wrapper">
        <div className="tinderCardWithTitle__wrapper__left">
          <h3 className="hero__content__subtitle text-black productsCollection__wrapper__left__subtitle">
            SS2024
          </h3>
          <h3 className="hero__content__title text-black productsCollection__wrapper__left__title">
            Featured Dresses
          </h3>
          <div className="hero__content__btn productsCollection__wrapper__left__btn">
            Shop Now
          </div>
        </div>
        <div className="tinderCardWithTitle__wrapper__right">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="tinderCardWithTitle__swiper"
          >
            <SwiperSlide>
              <img
                data-aos="zoom-in"
                alt="cards"
                src="/assets/images/iloveimg-resized/1.jpg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt="cards"
                data-aos="zoom-in"
                data-aos-duration="20000"
                src="/assets/images/iloveimg-resized/2.jpg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt="cards"
                data-aos="zoom-in"
                data-aos-duration="40000"
                src="/assets/images/iloveimg-resized/3.jpg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt="cards"
                data-aos="zoom-in"
                data-aos-duration="60000"
                src="/assets/images/iloveimg-resized/4.jpg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                alt="cards"
                data-aos="zoom-in"
                data-aos-duration="90000"
                src="/assets/images/iloveimg-resized/5.jpg"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TinderCardWithTitle;
